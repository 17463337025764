import React, { useEffect } from "react";
import "./App.css";
import "emfed";
import "./toots.css";
import { loadAll } from "emfed/dist/core";

import screenshot from "./assets/screenshot.jpg";
import vid from "./assets/noiselund.mp4";

function App() {
  useEffect(() => {
    loadAll();
  }, []);

  const ref = React.useRef<HTMLVideoElement>(null);

  const toggleSound = () => {
    if (ref.current) {
      ref.current.muted = !ref.current.muted;
    }
  };

  return (
    <div className="App">
      <header className="video-header">
        <video
          ref={ref}
          autoPlay
          muted
          controls
          loop
          playsInline
          poster={screenshot}
          preload="auto"
        >
          <source src={vid} type="video/mp4" />
          selaimesi ei tue html5 videoita
        </video>
      </header>

      <main>
        <div className="btn-div">
          <button onClick={toggleSound} className="button">
            <span>unmute </span>
          </button>
        </div>
        <div className="links-div">
          <div>
            <a
              rel="me"
              href="https://mastodontti.fi/@elias"
              data-testid="mastodon-link"
            >
              mastodon
            </a>
          </div>
          <div>
            <a
              href="https://bsky.app/profile/elias.bsky.fi"
              data-testid="bsky-link"
            >
              bsky
            </a>
          </div>
          <div>
            <a
              href="https://www.threads.net/@el1asla"
              data-testid="threads-link"
            >
              threads
            </a>
          </div>
        </div>
        <div className="toot-div">
          <p style={{ textAlign: "center", marginTop: "5px" }}>
            viimeisin toot mastodonissa
          </p>
          <a
            className="mastodon-feed"
            href="https://mastodontti.fi/@elias"
            data-toot-account-id="109275286552880136"
            data-toot-limit="1"
            data-testid="mastodon-link"
          >
            mastodon
          </a>
        </div>
      </main>
    </div>
  );
}

export default App;
